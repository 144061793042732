import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import Helmet from 'react-helmet'
import { url2section } from 'ion-sections'
import qs from 'qs'
import loadable from '@loadable/component'
import { DefaultSectionPageSkeleton, ArticlePageSkeletonWrapper, BlankPageSkeleton } from '../components/Skeletons'

const DefaultSectionPage = loadable(() => import('../pages/DefaultSectionPage'), { fallback: <DefaultSectionPageSkeleton /> })
const TagPage = loadable(() => import('../pages/TagPage'), { fallback: <BlankPageSkeleton /> })
const Article = loadable(() => import('../pages/Article'), { fallback: <ArticlePageSkeletonWrapper /> })
const NotFound = loadable(() => import('../pages/NotFound'))
const News = loadable(() => import('../routes/news'), { fallback: <BlankPageSkeleton /> })
const Trends = loadable(() => import('../routes/trends'), { fallback: <BlankPageSkeleton /> })
const Business = loadable(() => import('../routes/business'), { fallback: <BlankPageSkeleton /> })
const BusinessReport = loadable(() => import('../routes/business-report'), { fallback: <BlankPageSkeleton /> })
const Technology = loadable(() => import('../routes/technology'), { fallback: <BlankPageSkeleton /> })
const PersonalFinance = loadable(() => import('../routes/personal-finance'), { fallback: <BlankPageSkeleton /> })
const Sport = loadable(() => import('../routes/sport'), { fallback: <BlankPageSkeleton /> })
const Entertainment = loadable(() => import('../routes/entertainment'), { fallback: <BlankPageSkeleton /> })
const Lifestyle = loadable(() => import('../routes/lifestyle'), { fallback: <BlankPageSkeleton /> })
const Travel = loadable(() => import('../routes/travel'), { fallback: <BlankPageSkeleton /> })
const Opinion = loadable(() => import('../routes/opinion'), { fallback: <BlankPageSkeleton /> })
const Property = loadable(() => import('../routes/property'), { fallback: <BlankPageSkeleton /> })
const Motoring = loadable(() => import('../routes/motoring'), { fallback: <BlankPageSkeleton /> })
const Multimedia = loadable(() => import('../routes/multimedia'), { fallback: <BlankPageSkeleton /> })
const CapeTimes = loadable(() => import('../routes/capetimes'), { fallback: <BlankPageSkeleton /> })
const CapeArgus = loadable(() => import('../routes/capeargus'), { fallback: <BlankPageSkeleton /> })
const DailyNews = loadable(() => import('../routes/dailynews'), { fallback: <BlankPageSkeleton /> })
const IndependentOnSaturday = loadable(() => import('../routes/ios'), { fallback: <BlankPageSkeleton /> })
const TheMercury = loadable(() => import('../routes/mercury'), { fallback: <BlankPageSkeleton /> })
const PretoriaNews = loadable(() => import('../routes/pretoria-news'), { fallback: <BlankPageSkeleton /> })
const SaturdayStar = loadable(() => import('../routes/saturday-star'), { fallback: <BlankPageSkeleton /> })
const SundayIndependent = loadable(() => import('../routes/sunday-independent'), { fallback: <BlankPageSkeleton /> })
const SundayTribune = loadable(() => import('../routes/sunday-tribune'), { fallback: <BlankPageSkeleton /> })
const ThePost = loadable(() => import('../routes/the-post'), { fallback: <BlankPageSkeleton /> })
const TheStar = loadable(() => import('../routes/the-star'), { fallback: <BlankPageSkeleton /> })
const WeekendArgus = loadable(() => import('../routes/weekend-argus'), { fallback: <BlankPageSkeleton /> })

const DefaultSection = (props) => {
  const params = props.location ? qs.parse(props.location.search, { ignoreQueryPrefix: true }) : { page: 0 }
  const isPage0 = (params.page || 0) < 2
  return (
    <>
      <Helmet titleTemplate={props.titleTemplate} />
      <Switch>
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)/'} strict exact render={p => <Redirect to={props.match.url + '/' + p.match.params.section} />} />
        <Route path='/news/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='news'
              adPath='/news/test'
              relatedSections={[{ id: 'news/south-africa' }, { id: 'news/politics' }, { id: 'news/africa' }, { id: 'news/world' }, { id: 'news/eish' }]}
              title='IOL News | Breaking News from SA, Africa and Around the World'
              description='Top stories and news highlights from around South Africa, Africa and across the globe as they happen. Read the latest local and International news on IOL today.'
            />
          </>} />
        <Route path='/business-report/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='business-report'
              adPath='/business-report/test'
              relatedSections={[{ id: 'business-report/companies' }, { id: 'business-report/economy' }, { id: 'business-report/energy' }, { id: 'business-report/markets' }, { id: 'business-report/entrepreneurs' }, { id: 'business-report/technology' }, { id: 'business/jobs' }, { id: 'business-report/international' }, { id: 'business-report/opinion' }, { id: 'business-report/budget' }, { id: 'business-report/belt-and-road' }, { id: 'business-report/brics' }]}
              title='Business Report | Get The Latest South African Business News'
              description='Business Report is South Africa’s largest daily financial publication. We provide the best and latest business and technology news across South Africa and the world.'
              articleTitle='IOL Business Report'
            />
          </>} />
        <Route path='/personal-finance/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='personal-finance'
              adPath='/personal-finance/test'
              // relatedSections={[{ id: 'personal-finance/my-money' }, { id: 'personal-finance/debt' }, { id: 'personal-finance/financial-planning' }, { id: 'personal-finance/retirement' }, { id: 'personal-finance/investments' }, { id: 'personal-finance/insurance' }, { id: 'personal-finance/medical-cover' }, { id: 'personal-finance/tax' }, { id: 'personal-finance/money-tools' }]}
              relatedSections={[{ id: 'personal-finance/my-money' }, { id: 'personal-finance/debt' }, { id: 'personal-finance/financial-planning' }, { id: 'personal-finance/retirement' }, { id: 'personal-finance/investments' }, { id: 'personal-finance/insurance' }, { id: 'personal-finance/medical-cover' }, { id: 'personal-finance/tax' }]}
              title='Personal Finance | Money Matters Simplified'
              description='Personal Finance is South Africa`s leading publication of financial planning, investment, retirement and debt management news. Sound financial advice for everyday people.'
              articleTitle='IOL Personal Finance'
            />
          </>} />
        <Route path='/sport/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='sport'
              adPath='/sport/test'
              relatedSections={[{ id: 'sport/soccer' }, { id: 'sport/rugby' }, { id: 'sport/athletics' }, { id: 'sport/golf' }, { id: 'sport/tennis' }, { id: 'sport/boxing' }, { id: 'sport/cycling' }, { id: 'sport/opinion' }]}
              title='Test page | IOL'
              description='Test page for marketing purposes'
            />
          </>} />
        <Route path='/entertainment/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='entertainment'
              adPath='/entertainment/test'
              relatedSections={[{ id: 'entertainment/celebrity-news' }, { id: 'entertainment/whats-on' }, { id: 'entertainment/tv' }, { id: 'entertainment/movies' }, { id: 'entertainment/music' }, { id: 'entertainment/books' }, { id: 'entertainment/gaming' }, { id: 'entertainment/royals' }, { id: 'entertainment/trevor-noah' }, { id: 'entertainment/grahamstown-festival' }]}
              title='IOL Entertainment | Latest Celeb, Showbiz, Movie & TV News'
              description='South Africa`s premier source for the latest in entertainment, showbiz & celebrity news. Check out the hottest entertainment news today!'
              articleTitle='IOL Entertainment'
            />
          </>} />
        <Route path='/lifestyle/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='lifestyle'
              adPath='/lifestyle/test'
              relatedSections={[{ id: 'lifestyle/love-sex', label: 'Love & Sex' }, { id: 'lifestyle/family' }, { id: 'lifestyle/health' }, { id: 'lifestyle/food-and-restaurants', label: 'Food & Restaurants' }, { id: 'lifestyle/home-garden', label: 'Home & Garden' }, { id: 'lifestyle/style-beauty', label: 'Style & Beauty' }]}
              title='IOL Lifestyle | Food, Gardening, Wellbeing'
              description='Get the latest in local and international lifestyle news, fashion, beauty, health, parenting, home decor, recipes, reviews, advice and more on IOL Lifestyle.'
            />
          </>} />
        <Route path='/travel/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='travel'
              adPath='/travel/test'
              relatedSections={[{ id: 'travel/travel-news' }, { id: 'travel/south-africa' }, { id: 'travel/africa' }, { id: 'travel/world' }, { id: 'travel/travel-tips' }]}
              title='IOL Travel | Latest Travel News, Tips, Guides and Reviews'
              description='Get the best latest local and international travel industry news. Find out how you can experience adventure, activities and accommodation around the world.'
              articleTitle='IOL Travel'
            />
          </>} />
        <Route path='/motoring/test' exact render={(p) =>
          <>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <DefaultSectionPage {...props} {...p} isPage0={isPage0}
              section='motoring'
              adPath='/motoring/test'
              relatedSections={[{ id: 'motoring/latest-launches' }, { id: 'motoring/road-tests' }, { id: 'motoring/f1-grand-prix' }, { id: 'motoring/motorsport' }, { id: 'motoring/industry-news' }, { id: 'motoring/cars' }, { id: 'motoring/bikes' }, { id: 'motoring/trucks' }, { id: 'motoring/custom-cars' }]}
              title='IOL Motoring | Get Up to Speed on SA Motoring News'
              description='Your instant fix of all things motoring, from the latest vehicle industry news to comprehensive reviews, car videos, informative features, road tests and more.'
              articleTitle='IOL Motoring'
            />
          </>} />
        <Route path='/news'>
          <News {...props} isPage0={isPage0}
            instagram='https://www.instagram.com/iolnews'
          />
        </Route>
        <Route path='/business'>
          <Business {...props} isPage0={isPage0}
            instagram='https://www.instagram.com/iolnews'
          />
        </Route>
        <Route path='/business-report'>
          <BusinessReport {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/BusinessReportZA'
            twitter='https://www.twitter.com/busrep'
            instagram='https://www.instagram.com/business.report'
          />
        </Route>
        <Route path='/technology'>
          <Technology {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/IOLTECHNOLOGY/'
            twitter='https://www.twitter.com/TechIol'
            instagram='https://www.instagram.com/iol_tech/'
          />
        </Route>
        <Route path='/personal-finance'>
          <PersonalFinance {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/persfinza'
            twitter='https://www.twitter.com/persfinza'
          />
        </Route>
        <Route path='/sport'>
          <Sport {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/iolsport/'
            twitter='https://www.twitter.com/IOLsport'
            instagram='https://www.instagram.com/IOLsport'
          />
        </Route>
        <Route path='/entertainment'>
          <Entertainment {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/IOLlifestyle'
            instagram='https://www.instagram.com/iol_lifestyle'
            twitter='https://www.twitter.com/IOL_Lifestyle'
          />
        </Route>
        <Route path='/lifestyle'>
          <Lifestyle {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/IOLlifestyle'
            twitter='https://www.twitter.com/IOL_Lifestyle'
            instagram='https://www.instagram.com/iol_lifestyle'
          />
        </Route>
        <Route path='/travel'>
          <Travel {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/IOLTravel'
            twitter='https://www.twitter.com/IOLTravel'
            instagram='https://www.instagram.com/iolnews'
          />
        </Route>
        <Route path='/opinion'>
          <Opinion {...props} isPage0={isPage0}
            instagram='https://www.instagram.com/iolnews'
          />
        </Route>
        <Route path='/property'>
          <Property {...props} isPage0={isPage0} />
        </Route>
        <Route path='/motoring'>
          <Motoring {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/iolmotoring'
            twitter='https://www.twitter.com/IOLmotoring'
            instagram='https://www.instagram.com/iolmotoring'
          />
        </Route>
        <Route path='/multimedia'>
          <Multimedia {...props} isPage0={isPage0} instagram='https://www.instagram.com/iolnews' />
        </Route>
        <Route path='/capetimes'>
          <CapeTimes {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/CapeTimes/'
            twitter='https://www.twitter.com/CapeTimesSA/'
            instagram='https://www.instagram.com/capetimessa/'
          />
        </Route>
        <Route path='/capeargus'>
          <CapeArgus {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/CapeArgus/'
            twitter='https://www.twitter.com/TheCapeArgus'
            instagram='https://www.instagram.com/thecapeargus/'
          />
        </Route>
        <Route path='/dailynews'>
          <DailyNews {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/DailyNewsSA/'
            twitter='https://www.twitter.com/DailyNewsSA'
            instagram='https://www.instagram.com/dailynews_sa/'
          />
        </Route>
        <Route path='/ios'>
          <IndependentOnSaturday {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/IOSnewsSA/'
            twitter='https://www.twitter.com/IOSNewsSA'
            instagram='https://www.instagram.com/iosnews_sa/'
          />
        </Route>
        <Route path='/mercury'>
          <TheMercury {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/TheMercurySA/'
            twitter='https://www.twitter.com/TheMercurySA'
            instagram='https://www.instagram.com/the_mercury_sa/'
          />
        </Route>
        <Route path='/pretoria-news'>
          <PretoriaNews {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/PretoriaNewsSA/'
            twitter='https://www.twitter.com/pretorianews'
            instagram='https://www.instagram.com/pretoria.news/'
          />
        </Route>
        <Route path='/saturday-star'>
          <SaturdayStar {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/SaturdayStar/'
            twitter='https://www.twitter.com/SaturdayStar'
            instagram='https://www.instagram.com/thesaturday_star/'
          />
        </Route>
        <Route path='/sundayindependent'>
          <SundayIndependent {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/thesundayindy/'
            twitter='https://www.twitter.com/SundayIndy'
            instagram='https://www.instagram.com/thesundayindependent/'
          />
        </Route>
        <Route path='/sunday-tribune'>
          <SundayTribune {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/SundayTribuneSA/'
            twitter='https://www.twitter.com/SundayTribuneSA'
            instagram='https://www.instagram.com/sundaytribunesa/'
          />
        </Route>
        <Route path='/thepost'>
          <ThePost {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/postnewspaper/'
            twitter='https://www.twitter.com/PostNewspaperSA'
            instagram='https://www.instagram.com/post_newspaper/'
          />
        </Route>
        <Route path='/the-star'>
          <TheStar {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/TheStarSA/'
            twitter='https://www.twitter.com/TheStar_news'
            instagram='https://www.instagram.com/thestar_news/'
          />
        </Route>
        <Route path='/weekend-argus'>
          <WeekendArgus {...props} isPage0={isPage0}
            facebook='https://www.facebook.com/WeekendArgus/'
            twitter='https://www.twitter.com/WeekendArgus'
            instagram='https://www.instagram.com/weekendargus/'
          />
        </Route>
        <Route path='/trends'>
          <Trends {...props} isPage0={isPage0} />
        </Route>
        <Route path='/education/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path='/education/:title*([-])*:contentKey([0-9]{5,})' render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path='/:section([a-zA-Z0-9é/-]+)' exact render={(p) =>
          <TagPage {...props} {...p}
            isPage0={isPage0}
            section={url2section(props.location.pathname)}
            url={props.location.pathname}
          />} />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9]{5,})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path='*' render={(p) => <NotFound {...props} {...p} />} />
      </Switch>
    </>
  )
}

export default DefaultSection
